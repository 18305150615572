import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button'
import { AddOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Modal, Fade, Alert } from '@mui/material';
import CreateModal from './create-modal';
import DecorationsList from './list'
import { API_BASE_URL } from '../../constants';
import { getCookie } from '../../utils/cookie';
import './decorations.scss';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p: 4,
};
  

const DecorationsPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [flag, setFlag] = useState('');
    const [refreshData, setRefreshData] = useState(true);
    const [message, setMessage] = useState('');

    const onDeleteSubmit = async () => {
        const token = getCookie('__uls')
        const deleteReq = await fetch(`${API_BASE_URL}/decorations/delete/${selectedItem._id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'x-token-code': token
            },
        });
        const deleteRes = await deleteReq.json();
        const { success, message } = deleteRes;
        setShowAlert(true);
        setFlag(success ? 'success' : 'error');
        setMessage(message);
        onDeleteCloseModal(false);
        setRefreshData(true);
    };

    const onClose = () => {
        setShowModal(false);
    };

    const onDeleteCloseModal = () => {
        setDeleteModal(false);
        setSelectedItem(null);
    }

    const onCreate = () => {
        setRefreshData(false);
        setSelectedItem(null);
        setShowModal(true);
    }

    const onEdit = (item) => {
        setSelectedItem(item);
        setShowModal(true);
        setRefreshData(false);
    }

    const onDelete = (item) => {
        setSelectedItem(item);
        setDeleteModal(true);
        setRefreshData(false);
    }

    const onSubmit = (success, message) => {
        setShowAlert(true);
        setFlag(success ? 'success' : 'error');
        setMessage(message);
        setShowModal(false);
        setRefreshData(true);
    }

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, 3000)
        }
    }, [showAlert])

    return (
        <>
            <div className="decorations-page-container">
                {showAlert && (
                    <div className="alert-container">
                        <Alert variant="outlined" severity={flag}>
                            {message}
                        </Alert>
                    </div>
                )}
                <div className="top-container">
                    <div className="heading">
                        Decoration Items
                    </div>
                    <div className="cta">
                        <Button variant="contained" onClick={onCreate} startIcon={<AddOutlined />}>
                            Create
                        </Button>
                    </div>
                </div>
                <DecorationsList onDelete={onDelete} refreshData={refreshData} onEdit={onEdit} />
            </div>
            {showModal && (
                <Modal
                    open={showModal}
                    onClose={onClose}
                    aria-labelledby="Create Category"
                    aria-describedby="Form to create event category"
                >
                    <Fade in={showModal}>
                        <Box sx={style}>
                            <CreateModal
                                onClose={() => setShowModal(false)}
                                itemData={selectedItem}
                                isEdit={selectedItem !== null}
                                onSubmit={onSubmit}
                            />
                        </Box>
                    </Fade>
                </Modal>
            )}
            {deleteModal && (
                <Modal
                    open={deleteModal}
                    onClose={onDeleteCloseModal}
                    aria-labelledby="Delete Category"
                    aria-describedby="Delete category confirmation"
                >
                    <Fade in={deleteModal}>
                        <Box sx={style} className="delete-confirmation-modal">
                            <div className="heading">
                                Delete Category
                            </div>
                            <div className="desc">
                                Are you sure? Please click yes, if you still want to delete.
                            </div>
                            <div className="modal-buttons-container">
                                <LoadingButton
                                    variant="outlined"
                                    className="close-button"
                                    onClick={onDeleteCloseModal}>
                                    No
                                </LoadingButton>
                                <LoadingButton
                                    variant="contained"
                                    color="error"
                                    className="submit-button"
                                    onClick={onDeleteSubmit}>
                                    Yes
                                </LoadingButton>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            )}
        </>
    )
}

export default DecorationsPage;
