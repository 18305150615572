import React, { useEffect, useState } from 'react';
import {
  CakesPage,
  CategoriesPage,
  FoodPage,
  LoginPage,
  DecorationsPage,
  PhotographyPage,
  TheatresPage,
  SlotsPage,
  BookingsPage,
  BannersPage
} from './pages';
import {
  Route,
  Routes,
} from 'react-router';
import { getCookie } from './utils/cookie'
import PageWrapperComponent from './components/page-wrapper';
import Loader from './components/loader';

const NotFound = () => {
  <div className="not-found-container">404 | Not found any matched route.</div>;
};
/*Routes is used to be Switch*/
const AppRouter = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isLoggedIn = getCookie('__uls');
    if (!isLoggedIn) {
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000)
  }, []);

  return (
    <>
      {isLoading && (
        <div className="page-loader-container">
          <Loader />
        </div>
      )}
      {!isLoading && (
        <Routes>
          <Route path="/" element={
            <PageWrapperComponent>
              <TheatresPage />
            </PageWrapperComponent>
          } />
          <Route path="/slots" element={
            <PageWrapperComponent>
              <SlotsPage />
            </PageWrapperComponent>
          } />
          <Route path="/events" element={
            <PageWrapperComponent>
              <CategoriesPage />
            </PageWrapperComponent>
          } />
          <Route path="/cakes" element={
            <PageWrapperComponent>
              <CakesPage />
            </PageWrapperComponent>
          } />
          <Route path="/food" element={
            <PageWrapperComponent>
              <FoodPage />
            </PageWrapperComponent>
          } />
          <Route path="/decorations" element={
            <PageWrapperComponent>
              <DecorationsPage />
            </PageWrapperComponent>
          } />
          <Route path="/photography" element={
            <PageWrapperComponent>
              <PhotographyPage />
            </PageWrapperComponent>
          } />
          <Route path="/bookings" element={
            <PageWrapperComponent>
              <BookingsPage />
            </PageWrapperComponent>
          } />
          <Route path="/banners" element={
            <PageWrapperComponent>
              <BannersPage />
            </PageWrapperComponent>
          } />
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={
            <PageWrapperComponent>
              <NotFound />
            </PageWrapperComponent>
          } />
        </Routes>
      )}
    </>
  );
};
export default AppRouter;
