import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button'
import { AddOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Modal, Fade, Alert } from '@mui/material';
import CreateModal from './create-modal';
import EventCategoryList from './list'
import { API_BASE_URL } from '../../constants';
import { getCookie } from '../../utils/cookie';
import Loader from '../../components/loader';
import NoDataFound from '../../components/no-data';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './slots.scss';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p: 4,
};
  

const SlotsPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [flag, setFlag] = useState('');
    const [refreshData, setRefreshData] = useState(true);
    const [message, setMessage] = useState('');
    const [date, setDate] = useState(new Date());
    const [theatres, setTheatres] = useState([]);
    const [availabilityData, setAvailabilityData] = useState({});

    const onDeleteSubmit = async () => {
        const token = getCookie('__uls')
        const deleteReq = await fetch(`${API_BASE_URL}/slots/delete/${selectedItem._id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'x-token-code': token
            },
        });
        const deleteRes = await deleteReq.json();
        const { success, message } = deleteRes;
        setShowAlert(true);
        setFlag(success ? 'success' : 'error');
        setMessage(message);
        onDeleteCloseModal(false);
        setRefreshData(true);
    };

    const onClose = () => {
        setShowModal(false);
    };

    const onDeleteCloseModal = () => {
        setDeleteModal(false);
        setSelectedItem(null);
    }

    const onCreate = () => {
        setRefreshData(false);
        setSelectedItem(null);
        setShowModal(true);
    }

    const onEdit = (item) => {
        setSelectedItem(item);
        setShowModal(true);
        setRefreshData(false);
    }

    const onDelete = (item) => {
        setSelectedItem(item);
        setDeleteModal(true);
        setRefreshData(false);
    }

    const onSubmit = (success, message) => {
        setShowAlert(true);
        setFlag(success ? 'success' : 'error');
        setMessage(message);
        setShowModal(false);
        setRefreshData(true);
    }

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, 3000)
        }
    }, [showAlert])

    const [slotDetails, setSlotDetails] = useState({
        date: '',
        slot: '',
    });

    const onDateChange = (val) => {
        const formattedDate = dayjs(val).format('MM/DD/YYYY');
        setDate(formattedDate);
        const initialSlotDetails = {
            date: dayjs(val).format('MM/DD/YYYY'),
            slot: '',
        };
        setSlotDetails(initialSlotDetails);
        // fetchTheatresList(formattedDate);
    }

    const fetchTheatresList = async () => {
        setIsLoading(true);
        const req = await fetch(`${API_BASE_URL}/theatres/get`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const response = await req.json();
        setTheatres(response.data);
        setIsLoading(false);

        response?.data.forEach((theater) => {
            theater?.slots.forEach((slot) => {
                fetchSlotsAvailability(theater._id, slot, theater.name);
            });
        });
    }

    const fetchSlotsAvailability = async (theatreId, slot, name) => {
        const request = await fetch(`${API_BASE_URL}/bookings/check-availability`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                theatreId,
                date: dayjs(date).format('MM/DD/YYYY'),
                time: slot?.timeslot,
            })
        });
        const data = await request.json();

        setAvailabilityData(prevState => ({
            ...prevState,
            [theatreId]: {
                ...prevState[theatreId],
                [slot?.timeslot]: {
                    isAvailable: data.success,
                }
            }
        }));
    }

    useEffect(() => {
        fetchTheatresList();
    }, [date]);

    return (
        <>
            <div className="slots-page-container">
                {showAlert && (
                    <div className="alert-container">
                        <Alert variant="outlined" severity={flag}>
                            {message}
                        </Alert>
                    </div>
                )}
                <div className="top-container">
                    <div className="heading">
                        Available Slots
                    </div>
                    <div className="cta">
                        <Button variant="contained" onClick={onCreate} startIcon={<AddOutlined />}>
                            Create
                        </Button>
                    </div>
                </div>
                <EventCategoryList onDelete={onDelete} refreshData={refreshData} onEdit={onEdit} />
                <div className="bottom-container">
                    <div className="heading">
                        Available Slots By Date
                    </div>
                    <div className="date-section">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker minDate={dayjs(new Date())} onChange={(val) => onDateChange(val)} className='create-form-input' label="Select Date" value={dayjs(date)} />
                        </LocalizationProvider>
                    </div>
                    <div className="data-section">
                        {isLoading && (
                            <Loader description="Fetching data..." />
                        )}
                        <div className="theatres-list-flext-container">
                            {!isLoading && Object.entries(availabilityData).map(([theatreId, theatreData]) => (
                                <div className='theatre-card-container' key={theatreId}>
                                    <div className="info-section">
                                        <div className="name">
                                            {theatres.find(theatre => theatre._id === theatreId)?.name}
                                        </div>
                                        <div className="slots-info-container">
                                            {Object.entries(theatreData).map(([slotTime, slotInfo]) => (
                                                <div className={`slot-item ${slotInfo.isAvailable ? 'available' : 'not-available'}`} key={slotTime} >
                                                    {slotTime}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {!isLoading && theatres?.length === 0 && (
                                <NoDataFound />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <Modal
                    open={showModal}
                    onClose={onClose}
                    aria-labelledby="Create Slot"
                    aria-describedby="Form to create Slot"
                >
                    <Fade in={showModal}>
                        <Box sx={style}>
                            <CreateModal
                                onClose={() => setShowModal(false)}
                                itemData={selectedItem}
                                isEdit={selectedItem !== null}
                                onSubmit={onSubmit}
                            />
                        </Box>
                    </Fade>
                </Modal>
            )}
            {deleteModal && (
                <Modal
                    open={deleteModal}
                    onClose={onDeleteCloseModal}
                    aria-labelledby="Delete Slot"
                    aria-describedby="Delete Slot confirmation"
                >
                    <Fade in={deleteModal}>
                        <Box sx={style} className="delete-confirmation-modal">
                            <div className="heading">
                                Delete Slot
                            </div>
                            <div className="desc">
                                Are you sure? Please click yes, if you still want to delete.
                            </div>
                            <div className="modal-buttons-container">
                                <LoadingButton
                                    variant="outlined"
                                    className="close-button"
                                    onClick={onDeleteCloseModal}>
                                    No
                                </LoadingButton>
                                <LoadingButton
                                    variant="contained"
                                    color="error"
                                    className="submit-button"
                                    onClick={onDeleteSubmit}>
                                    Yes
                                </LoadingButton>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            )}
        </>
    )
}

export default SlotsPage;
