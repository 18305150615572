import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FormControl, TextField, OutlinedInput, MenuItem, InputLabel, Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { API_BASE_URL } from '../../constants';
import { getMinutes, getMinutesNow } from '../../utils/common';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CreateModal = ({ onClose, onSubmit }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedTheatre, setSelectedTheatre] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [slots, setSlots] = useState([]);
    const [theatres, setTheatres] = useState([]);
    const [slotIndex, setSlotIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [date, setDate] = useState(new Date());
    const [userDetails, setUserDetails] = useState({
        name: '',
        mobileNumber: '',
        specialPersonName: '',
    });

    const [slotDetails, setSlotDetails] = useState({
        date: '',
        slot: '',
    });

    const onTheatreChange = (e) => {
        const {
            target: { value },
        } = e;
        setSelectedTheatre(value);
        const theatreSlots = theatres.filter((item) => item.name === value)?.[0]?.slots || [];
        setSlots(theatreSlots);
        setSlotIndex(0)
    };

    const onSlotChange = (e) => {
        const {
            target: { value },
        } = e;
        setSelectedSlot(value);
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setUserDetails({
                ...userDetails,
                [name]: value
            })
        } else if (name === 'mobileNumber') {
            const updatedValue = value.replace(/[^0-9.]/g, '')
            if (updatedValue.length <= 10) {
                setUserDetails({
                    ...userDetails,
                    [name]: updatedValue
                })
            }
        } else {
            if (value.length <= 15) {
                setUserDetails({
                    ...userDetails,
                    [name]: value
                })
            }
        }
    }

    const createOrder = async () => {
        setIsSubmitting(true);
        const theaterId = theatres.filter((item) => item.name === selectedTheatre)?.[0]?._id;
        const payload = {
            theatre: theaterId,
            slot: {
                time: selectedSlot,
                date: dayjs(date).format('MM/DD/YYYY'),
            },
            addOns: [],
            orderAmount: 0,
            specialPersonName: userDetails?.specialPersonName,
            userDetails,
            extraPersons: 0,
            optInForFood: false,
            razorpayDetails: {}
        }
        console.log('payload', payload);

        const postReq = await fetch(`${API_BASE_URL}/bookings/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });
        const postRes = await postReq.json();
        const { success, message } = postRes;
        onSubmit(success, message)
    }

    const fetchTheatresList = async () => {
        setIsLoading(true);
        const req = await fetch(`${API_BASE_URL}/theatres/get`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const response = await req.json();
        setTheatres(response.data);
        setIsLoading(false);
    }

    const onDateChange = (val) => {
        const formattedDate = dayjs(val).format('MM/DD/YYYY');
        setDate(formattedDate);
        const initialSlotDetails = {
            date: dayjs(val).format('MM/DD/YYYY'),
            slot: '',
        };
        setSlotDetails(initialSlotDetails);
        setSelectedSlot('');
    }

    const fetchSlotsAvailability = async (index) => {
        const updatedSlots = [...slots];
        const slot = slots?.[index];
        const theatreId = theatres.filter((item) => item.name === selectedTheatre)?.[0]?._id;
        if (slot) {
            const request = await fetch(`${API_BASE_URL}/bookings/check-availability`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    theatreId,
                    date: dayjs(date).format('MM/DD/YYYY'),
                    time: slot?.timeslot,
                })
            });
            const data = await request.json();

            const slotStartTime = slot?.timeslot?.split('-')?.[0];
            const now = getMinutesNow();
            const isPm = slotStartTime.includes('pm');
            const start = getMinutes(slotStartTime.replaceAll('am', '').replaceAll('pm', ''), isPm);
            const isCurrentDay = new Date(date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
            updatedSlots[index] = ({
                ...slot,
                isAvailable: isCurrentDay ? start > now && data.success : data.success
            });
            if (index < slots.length) {
                setSlotIndex(index + 1);
            }

            if (index + 1 === slots.length) {
                setIsLoading(false);
            }
            setSlots(updatedSlots);
        }
    }

    useEffect(() => {
        if (date && selectedSlot) {
            setSlotDetails({
                date: dayjs(date).format('MM/DD/YYYY'),
                slot: selectedSlot,
            })
        }
    }, [date, selectedSlot]);

    useEffect(() => {
        console.log('slots', slots, slotIndex)
        fetchSlotsAvailability(slotIndex);
    }, [slotIndex, slots])

    useEffect(() => {
        setSelectedSlot(null);
        setSlotIndex(0);
        setIsLoading(true)
    }, [date])

    useEffect(() => {
        fetchTheatresList();
    }, []);

    const isDisabled = !userDetails?.name || userDetails?.mobileNumber?.length !== 10 || !userDetails?.specialPersonName
    return (
        <div className="create-modal-container">
            <div className="heading">
                Create Booking
            </div>
            <TextField
                id="name"
                label="Name"
                name="name"
                value={userDetails.name}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <TextField
                id="mobileNumber"
                label="Mobile Number"
                name="mobileNumber"
                value={userDetails.mobileNumber}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <TextField
                id="specialPerson"
                label="Special Person Name"
                name="specialPersonName"
                value={userDetails.specialPersonName}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <FormControl className='create-form-input'>
                <InputLabel id="theatre-label">Theatre</InputLabel>
                <Select
                    labelId="theatre-label"
                    id="theatre"
                    value={selectedTheatre}
                    onChange={onTheatreChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                        return selected
                    }}
                    MenuProps={MenuProps}
                >
                    {theatres?.map((theatre) => (
                        <MenuItem key={theatre?._id} value={theatre?.name}>
                            {theatre.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="date-section">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker minDate={dayjs(new Date())} onChange={(val) => onDateChange(val)} className='create-form-input' label="Select Date" value={dayjs(date)} />
                </LocalizationProvider>
            </div>
            <FormControl className='create-form-input'>
                <InputLabel id="slot-label">Slot</InputLabel>
                <Select
                    labelId="slot-label"
                    id="slot"
                    value={selectedSlot}
                    onChange={onSlotChange}
                    input={<OutlinedInput label="Slot" />}
                >
                    {slots?.map((item) => (
                        <MenuItem disabled={!item.isAvailable} key={item?.timeslot} value={item?.timeslot}>
                            {item?.timeslot}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="modal-buttons-container">
                <LoadingButton
                    variant="outlined"
                    className="close-button"
                    onClick={onClose}>
                    Close
                </LoadingButton>
                <LoadingButton
                    variant="contained"
                    className="submit-button"
                    disabled={isDisabled}
                    loading={isSubmitting}
                    onClick={createOrder}>
                    Submit
                </LoadingButton>
            </div>
        </div>
    )
}

export default CreateModal;
