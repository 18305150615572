import React from 'react';
import loaderImage from '../assets/loading-load.gif'

const Loader = ({ description = 'Loading...' }) => {
    return (
        <div className="app-loader-container">
            <div className="loader-image">
                <img src={loaderImage} alt="Loading" />
            </div>
            <div className="description">
                {description}
            </div>
        </div>
    )
}

export default Loader;
