import React from 'react';
import { AccountCircleOutlined, LogoutRounded } from '@mui/icons-material';
import { deleteCookie } from '../utils/cookie';

const LoginHeader = () => {

    const onLogout = () => {
        deleteCookie('__uls');
        window.location.href = '/';
    }

    return (
        <div className="login-header-container">
            <div className="user-details">
                <AccountCircleOutlined />
                <span>
                    Admin
                </span>
            </div>
            <div className="logout-button" onClick={onLogout}>
                <LogoutRounded />
            </div>
        </div>
    )
}

export default LoginHeader;
