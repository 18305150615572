import React, { useState } from 'react';

import { orderBy } from 'lodash';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

const DataTable = ({ data, columns, maxHeight = 440 }) => {
  const [sortAppliedObj, setSortAppliedObj] = useState({});

  const onSort = (column) => {
    const { id } = column;
    const updatedObj = {};
    const sortObjKeys = Object.keys(sortAppliedObj);
    if (sortObjKeys.includes(id)) {
      updatedObj[id] = sortAppliedObj?.[id] === "asc" ? "desc" : "asc";
    } else {
      updatedObj[id] = "asc";
    }
    setSortAppliedObj(updatedObj);
  };
  const sortAppliedObjKeys = Object.keys(sortAppliedObj);
  const sortAppliedValues = sortAppliedObjKeys?.map(
    (key) => sortAppliedObj[key]
  );
  const sortedResults = orderBy(data, sortAppliedObjKeys, sortAppliedValues);
  return (
    <TableContainer sx={{ maxHeight: maxHeight }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={Date.now() + index}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  fontWeight: "bold",
                  color: "#333",
                  background: "rgb(223 232 246)",
                }}
                onClick={() => onSort(column)}
              >
                <TableSortLabel
                  active={sortAppliedObjKeys?.includes(column.id)}
                  direction={sortAppliedObj?.[column.id] || "asc"}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedResults.map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {sortedResults.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length} align='center'>
                No record found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(DataTable);
