import React, { useEffect, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { AccessTimeOutlined, DeleteOutline, EditOutlined } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import DataTable from '../../components/data-table'
import { getCookie } from '../../utils/cookie';
import { API_BASE_URL } from '../../constants';
import Loader from '../../components/loader';

const tableColumns = [
    {
        id: 'name',
        label: 'Name'
    },
    {
        id: 'description',
        label: 'Description'
    },
    {
        id: 'timeslot',
        label: 'Time Slot'
    },
    {
        id: 'actions',
        label: 'Actions'
    }
];

const FoodList = ({ onEdit, refreshData, onDelete }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);

    const fetchListData = async () => {
        setIsLoading(true);
        const cookie = getCookie('__uls');
        const listReq = await fetch(`${API_BASE_URL}/slots/get`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-token-code': cookie,
            }
        });
        const resData = await listReq.json();
        const { data } = resData;
        setList(data);
        setIsLoading(false);
    };

    const tableRows = useMemo(() => {
        return list?.map((item) => ({
            id: nanoid(),
            name: (
                <div className="name-image-container">
                    <div className="name">
                        {item.name}
                    </div>
                </div>
            ),
            description: item.description,
            timeslot: (
                <div className='time-container'>
                    <AccessTimeOutlined />
                    {item.timeslot}
                </div>
            ),
            actions: (
                <div className="actions-container">
                    <IconButton color="primary" size="small" onClick={() => onEdit(item)}>
                        <EditOutlined />
                    </IconButton>
                    <IconButton color="error" size="small" onClick={() => onDelete(item)}>
                        <DeleteOutline />
                    </IconButton>

                </div>
            )
        }));
    }, [list]);

    useEffect(() => {
        if (refreshData) {
            fetchListData();
        }
    }, [refreshData]);

    return (
        <div className="event-category-list-container">
            {isLoading && (
                <Loader description="Fetching data..." />
            )}
            {!isLoading && (
                <DataTable
                    data={tableRows}
                    columns={tableColumns}
                    maxHeight={600}
                />
            )}
        </div>
    )
}

export default FoodList;
