import React, { useEffect, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { AccessTimeOutlined, CurrencyRupee, CurrencyRupeeOutlined, DateRangeOutlined, Delete, DeleteOutline, EditOutlined, InfoOutlined } from '@mui/icons-material';
import DataTable from '../../components/data-table'
import { getCookie } from '../../utils/cookie';
import { API_BASE_URL } from '../../constants';
import Loader from '../../components/loader';
import { Box, Fade, IconButton, Modal, TablePagination } from '@mui/material';
import Button from '@mui/material/Button'
import { LoadingButton } from '@mui/lab';
import { modlStyle } from '../bookings'

const tableColumns = [
    {
        id: 'theatre',
        label: 'Theatre'
    },
    {
        id: 'slot',
        label: 'Slot'
    },
    {
        id: 'date',
        label: 'Slot Date'
    },
    {
        id: 'total',
        label: 'Order Total'
    },
    {
        id: 'tokenAmount',
        label: 'Token Amount'
    },
    {
        id: 'user',
        label: 'User Details'
    },
    {
        id: 'persons',
        label: 'People'
    },
    {
        id: 'food',
        label: 'Food'
    },
    {
        id: 'booking',
        label: 'Booking Date'
    },
    {
        id: 'addons',
        label: 'Actions'
    },
];

const BookingsList = ({ onEdit, refreshData, onDelete }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');

    const [showModal, setShowModal] = useState(false);
    const fomatter = new Intl.NumberFormat();

    const onAddOnInfoClick = (item) => {
        console.log('items', item)
        setSelectedItem(item);
        setShowModal(true);
    };

    const fetchListData = async () => {
        setIsLoading(true);
        const cookie = getCookie('__uls');
        const listReq = await fetch(`${API_BASE_URL}/bookings/get`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-token-code': cookie,
            }
        });
        const resData = await listReq.json();
        const { data } = resData;
        setList(data);
        setIsLoading(false);
    };

    const onRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const onPageChange = (event, page) => {
        setCurrentPage(page);
    };

    const onSearchChange = (e) => {
        setSearchQuery(e.target.value);
    }

    const formatDateTime = (dateTimeString) => {
        const dateObj = new Date(dateTimeString);
        if (isNaN(dateObj.getTime())) {
          return "Invalid Date";
        }
        const formattedDate = `${('0' + dateObj.getDate()).slice(-2)} ${getMonthName(dateObj.getMonth())} ${dateObj.getFullYear()}`;
        const formattedTime = `${('0' + dateObj.getHours()).slice(-2)}:${('0' + dateObj.getMinutes()).slice(-2)}`;
        return `${formattedDate}-${formattedTime}`;
      };
    
      const getMonthName = (monthIndex) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return months[monthIndex];
      };

      const formatDate = (dateTimeString) => {
        const dateObj = new Date(dateTimeString);
        if (isNaN(dateObj.getTime())) {
          return "Invalid Date";
        }
        const day = ('0' + dateObj.getDate()).slice(-2);
        const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
        const year = dateObj.getFullYear();
        return `${day}/${month}/${year}`;
      };

    const filteredResults = list.filter((item) => (
        `${item?.userDetails?.name}`?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        `${item?.userDetails?.mobileNumber}`?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        `${formatDate(item.slot?.date)}`?.toLowerCase()?.includes(searchQuery?.toLowerCase())

    ));
    const paginatedData = filteredResults?.slice(
        currentPage * rowsPerPage,
        rowsPerPage * (currentPage + 1)
    );

    const tableRows = useMemo(() => {
        return paginatedData?.map((item) => ({
            id: nanoid(),
            theatre: (
                <div className="name-image-container">
                    <div className="name">
                        {item.theatre?.name}
                    </div>
                </div>
            ),
            slot: (
                <div className='time-container'>
                    <AccessTimeOutlined />
                    {item.slot?.time}
                </div>
            ),
            date: (
                <div className='time-container'>
                    <DateRangeOutlined />
                    {formatDate(item.slot?.date)}
                </div>
            ),
            user: (
                <div className="user-info-container">
                    <span className="name">
                        {item?.userDetails?.name}
                    </span>
                    <span className="mobile-number">
                        {item?.userDetails?.mobileNumber}
                    </span>
                </div>

            ),
            total: (
                <div className='time-container'>
                    <CurrencyRupeeOutlined />
                    {fomatter?.format(item.orderAmount)}
                </div>
            ),
            tokenAmount: (
                <div className='price-container'>
                    <CurrencyRupeeOutlined />
                    {fomatter?.format(item?.razorpayDetails?.amount / 100)}
                </div>
            ),
            persons: (
                <div className="persons-container">
                    <div className="name">
                        {item?.theatre?.noOfPersons + item?.extraPersons}
                    </div>
                </div>
            ),
            food: (
                <div className="food-container">
                    <div className="name">
                        {item?.optInForFood ? 'Yes' : 'No'}
                    </div>
                </div>
            ),
            booking: (
                <div className='time-container'>
                    {formatDateTime(item?.createdAt)}
                </div>
            ),
            addons: (
                // <div class="info-icon-section" onClick={() => onAddOnInfoClick(item)}>
                //     <InfoOutlined />
                // </div>
                <div className="actions-container">
                    <IconButton color="primary" size="small" onClick={() => onAddOnInfoClick(item)}>
                        <InfoOutlined />
                    </IconButton>
                    <IconButton color="error" size="small" onClick={() => onDelete(item)}>
                        <DeleteOutline />
                    </IconButton>
                </div>
            ),
        }));
    }, [paginatedData]);

    useEffect(() => {
        if (refreshData) {
            fetchListData();
        }
    }, [refreshData]);

    return (
        <div className="event-category-list-container bookings-list-container">
            {isLoading && (
                <Loader description="Fetching data..." />
            )}
            {!isLoading && (
                <>
                    <div className="search-bar-container">
                        <input placeholder='Search' value={searchQuery} onChange={onSearchChange} />
                    </div>
                    <DataTable
                        data={tableRows}
                        columns={tableColumns}
                        maxHeight={600}
                    />
                    <TablePagination
                        component="div"
                        count={list.length}
                        page={currentPage}
                        onPageChange={onPageChange}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </>
            )}

            {showModal && (
                <Modal
                    open={showModal}
                    onClose={() => { setShowModal(false); setSelectedItem(null) }}
                    aria-labelledby="Delete Slot"
                    aria-describedby="Delete Slot confirmation"
                >
                    <Fade in={showModal}>
                        <Box sx={modlStyle} className="addon-info-modal">
                            <div className="heading">
                                Selected Addons
                            </div>
                            <div className="item-info-container">
                                <div className="info-heading">
                                    Special Person name
                                </div>
                                <div className="special-person-name-container">
                                    <div className="name">
                                        {selectedItem?.specialPersonName}
                                    </div>
                                </div>
                            </div>
                            {Object.keys(selectedItem?.addOns)?.map((item) => {
                                const isItemPresent = selectedItem?.addOns[item].length > 0;
                                const data = selectedItem?.addOns[item];
                                return (
                                    <>
                                        {isItemPresent ? (
                                            <div className="item-info-container">
                                                <div className="info-heading">
                                                    {item === "food" ? "Gift" : item}
                                                </div>
                                                {data?.map((item) => (
                                                    <div className="add-on-item-info-container">
                                                        <div className="name">
                                                            {item.name}
                                                        </div>
                                                        <div className="price">
                                                            <CurrencyRupee />
                                                            {fomatter?.format(item.price)}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : <></>}
                                    </>
                                )
                            })}
                            <div className="modal-buttons-container">
                                <LoadingButton
                                    variant="contained"
                                    className="close-button"
                                    onClick={() => { setShowModal(false); setSelectedItem(null) }}>
                                    Close
                                </LoadingButton>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            )}
        </div>
    )
}

export default BookingsList;
