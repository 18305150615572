import React, { useEffect, useState } from 'react';
import { CurrencyRupeeOutlined, DeleteOutline, EditOutlined } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { getCookie } from '../../utils/cookie';
import { API_BASE_URL } from '../../constants';
import Loader from '../../components/loader';
import NoDataFound from '../../components/no-data';

const EventCategoryList = ({ onEdit, refreshData, onDelete }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);

    const fetchListData = async () => {
        setIsLoading(true);
        const cookie = getCookie('__uls');
        const listReq = await fetch(`${API_BASE_URL}/decorations/get`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-token-code': cookie,
            }
        });
        const resData = await listReq.json();
        const { data } = resData;
        setList(data);
        setIsLoading(false);
    };

    useEffect(() => {
        if (refreshData) {
            fetchListData();
        }
    }, [refreshData]);

    return (
        <div className="event-category-list-container">
            {isLoading && (
                <Loader description="Fetching data..." />
            )}
            <div className="list-flex-container">
                {!isLoading && list?.map((item) => (
                    <div className='theatre-card-container'>
                        <div className="image-section">
                            <img src={item?.imageUrl} alt={item?.name} />
                        </div>
                        <div className="info-section">
                            <div className="name">
                                {item?.name}
                            </div>
                            <div className="desc">
                                {item?.description}
                            </div>
                            <div className="price">
                                <div className="left">
                                    <CurrencyRupeeOutlined />
                                    {item?.price}
                                </div>
                                <div className="right">
                                    <div className="actions-container">
                                        <IconButton color="primary" size="small" onClick={() => onEdit(item)}>
                                            <EditOutlined />
                                        </IconButton>
                                        <IconButton color="error" size="small" onClick={() => onDelete(item)}>
                                            <DeleteOutline />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {!isLoading && list?.length === 0 && (
                    <NoDataFound />
                )}
            </div>
        </div>
    )
}

export default EventCategoryList;
