import React from 'react';
import AppSideMenuLayout from './layout';
import LoginHeader from './login-header';

const PageWrapperComponent = ({ children }) => {
    return (
        <div className="app-page-flex-container">
            <AppSideMenuLayout />
            <div className='app-page-main-content'>
                <LoginHeader />
                {children}
            </div>
        </div>
    )
}

export default PageWrapperComponent;
