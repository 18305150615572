export const getBase64String = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
        return reader.result;
    };
    reader.onerror = error => {
        console.log('Error: ', error);
    };
}

export const getDateWithTimeString = (time) => {
    const dateObj = new Date();
    const parts = time?.toLowerCase()?.match(/(1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm])/);
    dateObj.setHours(0);
    dateObj.setMinutes(0);
    const hours = parts?.[3] === 'am' ? parseInt(parts[1], 10) : parseInt(parts[1], 10) === 12 ? parseInt(parts[1], 10) : parseInt(parts[1], 10) + 12;
    const minutes = parseInt(parts?.[2], 10);

    dateObj.setHours(hours);
    dateObj.setMinutes(minutes);
    return dateObj;
}

export function getMinutes(str, isPm) {
    var time = str.split(':');
    return time[0] * 60 + time[1] * 1 + (isPm ? 720 : 0);
}

export function getMinutesNow() {
    var timeNow = new Date();
    return timeNow.getHours() * 60 + timeNow.getMinutes();
}