import React from 'react';
import { FindInPage } from '@mui/icons-material';

const NoDataFound = () => {
    return (
        <div className="no-data-found-container">
            <FindInPage />
            No data found
        </div>
    )
}

export default NoDataFound;
