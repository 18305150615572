import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MuiFileInput } from 'mui-file-input'
import { API_BASE_URL } from '../../constants';
import { getCookie } from '../../utils/cookie';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CreateModal = ({ onClose, isEdit, itemData, onSubmit }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(0);
    const [slots, setSlots] = useState([]);
    const [slotsList, setSlotsList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [file, setFile] = useState([])
    const [noOfPersons, setNoOfPersons] = useState(4);
    const [maxNoOfPersons, setMaxNoOfPersons] = useState(6);
    const [costPerPerson, setCostPerPerson] = useState(400);

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setName(value)
        } else if (name === 'description') {
            setDescription(value)
        } else if (name === 'price') {
            setPrice(value);
        } else if (name === 'costPerPerson') {
            setCostPerPerson(value);
        } else if (name === 'maxNoOfPersons') {
            setMaxNoOfPersons(value)
        } else {
            setNoOfPersons(value);
        }
    };

    const onSlotChange = (e) => {
        const {
            target: { value },
        } = e;
        setSlots(value)
    };

    const handleChange = (files) => {
        console.log('files', files);
        files?.forEach((newFile) => {
            const reader = new FileReader();
            reader.readAsDataURL(newFile);

            reader.onload = () => {
                setFile((filesList) => ([
                    ...filesList,
                    reader.result
                ]));
            };
            reader.onerror = error => {
                console.log('Error: ', error);
            };
        })
    }

    const handleSubmit = async () => {
        const token = getCookie('__uls');
        setIsSubmitting(true);
        if (isEdit) {
            const slotItems = slotsList?.filter((item) => slots.includes(item.timeslot));
            const payload = {
                name,
                description,
                price,
                slots: slotItems,
                costPerPerson,
                noOfPersons,
                maxNoOfPersons,
            };

            if (file) {
                payload.carouselImages = file;
            } else {
                payload.carouselImageKeys = itemData?.carouselImages;
            }

            const putReq = await fetch(`${API_BASE_URL}/theatres/${itemData._id}`, {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    'x-token-code': token
                },
            });
            const putRes = await putReq.json();
            const { success, message } = putRes;
            onSubmit(success, message)
        } else {
            const slotItems = slotsList?.filter((item) => slots.includes(item.timeslot));
            const payload = {
                name,
                description,
                price,
                carouselImages: file,
                noOfPersons,
                maxNoOfPersons,
                costPerPerson,
                slots: slotItems,
            };

            const postReq = await fetch(`${API_BASE_URL}/theatres/add`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    'x-token-code': token
                },
            });
            const postRes = await postReq.json();
            const { success, message } = postRes;
            onSubmit(success, message)
        }
    }

    const fetchSlotsList = async () => {
        const cookie = getCookie('__uls');
        const listReq = await fetch(`${API_BASE_URL}/slots/get`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-token-code': cookie,
            }
        });
        const resData = await listReq.json();
        const { data } = resData;
        setSlotsList(data);
    };

    useEffect(() => {
        if (isEdit) {
            const { name, description, price, slots: slotItems, noOfPersons: np, costPerPerson: cp, maxNoOfPersons: mp } = itemData || {};
            setName(name);
            setDescription(description);
            setPrice(price);
            setFile('')
            setNoOfPersons(np);
            setMaxNoOfPersons(mp)
            setCostPerPerson(cp)
            const updatedSlotItems = slotItems?.map((item) => item?.timeslot)
            setSlots(updatedSlotItems)
        }
    }, [itemData, isEdit]);

    useEffect(() => {
        fetchSlotsList();
    }, [])

    const isDisabled = isEdit ?
        !name || !description || !price || !slots?.length :
        !name || !description || !price || !file || !slots?.length;

    console.log('file', file);
    return (
        <div className="create-modal-container" style={{ paddingTop: '10rem' }}>
            <div className="heading">
                {isEdit ? 'Edit ' : 'Create '}Theatre
            </div>
            {isEdit && itemData?.carouselImages && itemData?.carouselImages?.map((item) => (
                <div className="image-section">
                    <img src={item} alt="Name" />
                </div>
            ))}
            <TextField
                id="categoryname"
                label="Name"
                name="name"
                value={name}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <TextField
                id="description"
                label="Description"
                name="description"
                value={description}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <TextField
                id="price"
                label="Price"
                name="price"
                value={price}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <TextField
                id="noOfPersons"
                label="No of persons"
                name="noOfPersons"
                value={noOfPersons}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <TextField
                id="maxNoOfPersons"
                label="Maximum No of persons"
                name="maxNoOfPersons"
                value={maxNoOfPersons}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <TextField
                id="costPerPerson"
                label="Cost per extra person"
                name="costPerPerson"
                value={costPerPerson}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <FormControl className='create-form-input'>
                <InputLabel id="demo-multiple-checkbox-label">Slots</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    multiple
                    value={slots}
                    onChange={onSlotChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                        return selected?.join(', ')
                    }}
                    MenuProps={MenuProps}
                >
                    {slotsList?.map((item) => (
                        <MenuItem key={item?.timeslot} value={item?.timeslot}>
                            <Checkbox checked={slots?.indexOf(item?.timeslot) > -1} />
                            <ListItemText primary={item?.timeslot} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="file-input">
                <MuiFileInput
                    label="Image"
                    multiple
                    inputProps={{ accept: '.png, .jpeg' }}
                    onChange={handleChange}
                    placeholder="Insert a file"
                />
            </div>
            <div className="modal-buttons-container">
                <LoadingButton
                    variant="outlined"
                    className="close-button"
                    onClick={onClose}>
                    Close
                </LoadingButton>
                <LoadingButton
                    variant="contained"
                    className="submit-button"
                    disabled={isDisabled}
                    loading={isSubmitting}
                    onClick={handleSubmit}>
                    Submit
                </LoadingButton>
            </div>
        </div>
    )
}

export default CreateModal;
