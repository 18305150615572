import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router';
import { setCookie } from '../../utils/cookie';
import { API_BASE_URL } from '../../constants';
import './login.scss'

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email') {
            setEmail(value)
        } else {
            setPassword(value)
        }
    };

    const onSubmit = async (e) => {
        setIsSubmitting(true);
        const loginReq = await fetch(`${API_BASE_URL}/users/login`, {
            method: "POST",
            body: JSON.stringify({
                email,
                password
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const loginRes = await loginReq.json();
        const { data, success, message } = loginRes;
        const { token } = data || {};
        if (token && success) {
            setCookie('__uls', token, 1);
            navigate('/')
        } else {
            setHasError(true);
            setErrorMessage(message)
        }
        setIsSubmitting(false);
    }

    return (
        <div className="login-page-container">
            <div className="login-box">
                <div className="logo-header">
                    <span>Binge</span>Delight
                </div>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    className="login-form">
                    <TextField
                        id="outlined-error"
                        label="Email"
                        name="email"
                        value={email}
                        onChange={onChange}
                        autoComplete="off"
                        className='login-form-input'
                    />
                    <TextField
                        type="password"
                        value={password}
                        name="password"
                        onChange={onChange}
                        id="outlined-error-helper-text"
                        label="Password"
                        autoComplete="off"
                        className='login-form-input'
                    />
                    {hasError && (
                        <div className='error-message'>
                            {errorMessage}
                        </div>
                    )}
                    <LoadingButton
                        variant="contained"
                        className="login-button"
                        disabled={!email || !password}
                        loading={isSubmitting}
                        onClick={onSubmit}>Login</LoadingButton>
                </Box>
            </div>
        </div>
    )
}

export default LoginPage;
