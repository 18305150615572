import React from 'react';
import {
  BrowserRouter,
  Outlet,
} from 'react-router-dom';
import AppRouter from './routes';

function App() {
  return (
    <BrowserRouter>
      <div className="app-main-container">
        <AppRouter />
        <Outlet />
      </div>
    </BrowserRouter>
  );
}

export default App;
