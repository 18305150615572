import React from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import { CakeOutlined, CalendarMonthOutlined, CameraEnhanceOutlined, CelebrationOutlined, DesignServicesOutlined, CardGiftcardOutlined, LocalActivityOutlined, ShoppingBagOutlined, TheatersOutlined } from '@mui/icons-material';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import './layout.scss';

const AppSideMenuLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();


  const onRouteClick = (route) => {
    navigate(route);
  };

  return (
    <div
      className="app-side-menu-container"
    >
      <div className="app-logo-section" onClick={() => onRouteClick("/")}>
        <span>Binge</span>Delight
      </div>
      <List
        sx={{ width: "100%", maxWidth: 300, color: "#fff" }}
        component="nav"
        className="app-nav-links-container"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton
          selected={pathname === "/"}
          onClick={() => onRouteClick("/")}
        >
          <ListItemIcon className="app-nav-link-menu-item">
            <TheatersOutlined className="app-nav-link-menu-item-icon" />
          </ListItemIcon>
          <ListItemText
            className="app-nav-link-menu-item-text"
            primary="Theaters"
          />
        </ListItemButton>
        <ListItemButton
          selected={pathname === "/slots"}
          onClick={() => onRouteClick("/slots")}
        >
          <ListItemIcon className="app-nav-link-menu-item">
            <CalendarMonthOutlined className="app-nav-link-menu-item-icon" />
          </ListItemIcon>
          <ListItemText
            className="app-nav-link-menu-item-text"
            primary="Slots"
          />
        </ListItemButton>
        <ListItemButton
          selected={pathname === "/bookings"}
          onClick={() => onRouteClick("/bookings")}
        >
          <ListItemIcon className="app-nav-link-menu-item">
            <ShoppingBagOutlined className="app-nav-link-menu-item-icon" />
          </ListItemIcon>
          <ListItemText
            className="app-nav-link-menu-item-text"
            primary="Bookings"
          />
        </ListItemButton>
        <ListItemButton
          selected={pathname === "/events"}
          onClick={() => onRouteClick("/events")}
        >
          <ListItemIcon className="app-nav-link-menu-item">
            <CelebrationOutlined className="app-nav-link-menu-item-icon" />
          </ListItemIcon>
          <ListItemText
            className="app-nav-link-menu-item-text"
            primary="Event Categories"
          />
        </ListItemButton>
        <ListItemButton
          selected={pathname === "/food"}
          onClick={() => onRouteClick("/food")}
        >
          <ListItemIcon className="app-nav-link-menu-item">
            <CardGiftcardOutlined className="app-nav-link-menu-item-icon" />
          </ListItemIcon>
          <ListItemText
            className="app-nav-link-menu-item-text"
            primary="Gifts"
          />
        </ListItemButton>
        <ListItemButton
          selected={pathname === "/cakes"}
          onClick={() => onRouteClick("/cakes")}
        >
          <ListItemIcon className="app-nav-link-menu-item">
            <CakeOutlined className="app-nav-link-menu-item-icon" />
          </ListItemIcon>
          <ListItemText
            className="app-nav-link-menu-item-text"
            primary="Cakes"
          />
        </ListItemButton>
        <ListItemButton
          selected={pathname === "/banners"}
          onClick={() => onRouteClick("/banners")}
        >
          <ListItemIcon className="app-nav-link-menu-item">
            <LocalActivityOutlined className="app-nav-link-menu-item-icon" />
          </ListItemIcon>
          <ListItemText
            className="app-nav-link-menu-item-text"
            primary="Banners"
          />
        </ListItemButton>
        <ListItemButton
          selected={pathname === "/decorations"}
          onClick={() => onRouteClick("/decorations")}
        >
          <ListItemIcon className="app-nav-link-menu-item">
            <DesignServicesOutlined className="app-nav-link-menu-item-icon" />
          </ListItemIcon>
          <ListItemText
            className="app-nav-link-menu-item-text"
            primary="Decorations"
          />
        </ListItemButton>
        <ListItemButton
          selected={pathname === "/photography"}
          onClick={() => onRouteClick("/photography")}
        >
          <ListItemIcon className="app-nav-link-menu-item">
            <CameraEnhanceOutlined className="app-nav-link-menu-item-icon" />
          </ListItemIcon>
          <ListItemText
            className="app-nav-link-menu-item-text"
            primary="Photography"
          />
        </ListItemButton>
      </List>
    </div>
  );
};

export default AppSideMenuLayout;
