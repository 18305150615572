import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MuiFileInput } from 'mui-file-input'
import { API_BASE_URL } from '../../constants';
import { getCookie } from '../../utils/cookie';

const CreateModal = ({ onClose, isEdit, itemData, onSubmit }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [file, setFile] = useState(null)

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setName(value)
        } else if (name === 'description') {
            setDescription(value)
        } else {
            setPrice(value);
        }
    };

    const handleChange = (newFile) => {
        const reader = new FileReader();
        reader.readAsDataURL(newFile);
        
        reader.onload = () => {
            setFile(reader.result);
        };
        reader.onerror = error => {
            console.log('Error: ', error);
        };
    }

    const handleSubmit = async () => {
        const token = getCookie('__uls');
        setIsSubmitting(true);
        if (isEdit) {
            const payload = {
                name,
                description,
                price
            };

            if (file) {
                payload.imageData = file;
            }

            const putReq = await fetch(`${API_BASE_URL}/photography/${itemData._id}`, {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    'x-token-code': token
                },
            });
            const putRes = await putReq.json();
            const { success, message } = putRes;
            onSubmit(success, message)
        } else {
            const payload = {
                name,
                description,
                price,
                imageData: file,
            };

            const postReq = await fetch(`${API_BASE_URL}/photography/add`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    'x-token-code': token
                },
            });
            const postRes = await postReq.json();
            const { success, message } = postRes;
            onSubmit(success, message)
        }
    }

    useEffect(() => {
        if (isEdit) {
            const { name, description, price } = itemData || {};
            setName(name);
            setDescription(description);
            setPrice(price);
            setFile('')
        }
    }, [itemData, isEdit]);

    const isDisabled = isEdit ?
        !name || !description || !price :
        !name || !description || !price || !file;
    return (
        <div className="create-modal-container">
            <div className="heading">
                {isEdit ? 'Edit ' : 'Create '}category
            </div>
            {isEdit && itemData.imageUrl && (
                <div className="image-section">
                    <img src={itemData.imageUrl} alt="Name" />
                </div>
            )}
            <TextField
                id="categoryname"
                label="Name"
                name="name"
                value={name}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <TextField
                id="description"
                label="Description"
                name="description"
                value={description}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <TextField
                id="price"
                label="Price"
                name="price"
                value={price}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <div className="file-input">
                <MuiFileInput
                    label="Image"
                    inputProps={{ accept: '.png, .jpeg' }}
                    value={file}
                    onChange={handleChange}
                    placeholder="Insert a file"
                />
            </div>
            <div className="modal-buttons-container">
                <LoadingButton
                    variant="outlined"
                    className="close-button"
                    onClick={onClose}>
                    Close
                </LoadingButton>
                <LoadingButton
                    variant="contained"
                    className="submit-button"
                    disabled={isDisabled}
                    loading={isSubmitting}
                    onClick={handleSubmit}>
                    Submit
                </LoadingButton>
            </div>
       </div>
   ) 
}

export default CreateModal;
