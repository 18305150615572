import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { API_BASE_URL } from '../../constants';
import { getCookie } from '../../utils/cookie';
import { getDateWithTimeString } from '../../utils/common';
import dayjs from 'dayjs';

const CreateModal = ({ onClose, isEdit, itemData, onSubmit }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setName(value)
        } else if (name === 'description') {
            setDescription(value)
        }
    };

    const handleSubmit = async () => {
        const token = getCookie('__uls');
        setIsSubmitting(true);
        if (isEdit) {
            const payload = {
                name,
                description,
                timeslot: `${from} - ${to}`
            };

            const putReq = await fetch(`${API_BASE_URL}/slots/${itemData._id}`, {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    'x-token-code': token
                },
            });
            const putRes = await putReq.json();
            const { success, message } = putRes;
            onSubmit(success, message)
        } else {
            const payload = {
                name,
                description,
                timeslot: `${from} - ${to}`
            };

            const postReq = await fetch(`${API_BASE_URL}/slots/add`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    'x-token-code': token
                },
            });
            const postRes = await postReq.json();
            const { success, message } = postRes;
            onSubmit(success, message)
        }
    }

    useEffect(() => {
        if (isEdit) {
            const { name, description, timeslot } = itemData || {};
            setName(name);
            setDescription(description);
            const timeSlotStrings = timeslot.split('-');
            setFrom(getDateWithTimeString(timeSlotStrings?.[0]?.trim()));
            setTo(getDateWithTimeString(timeSlotStrings?.[1]?.trim()));
        }
    }, [itemData, isEdit]);

    const isDisabled = !name || !description || !from || !to
    return (
        <div className="create-modal-container">
            <div className="heading">
                {isEdit ? 'Edit ' : 'Create '}Slot
            </div>
            <TextField
                id="categoryname"
                label="Name"
                name="name"
                value={name}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <TextField
                id="description"
                label="Description"
                name="description"
                value={description}
                onChange={onChange}
                autoComplete="off"
                className='create-form-input'
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    label="From"
                    className='create-form-input'
                    value={dayjs(from)}
                    onChange={(newValue) => setFrom(dayjs(newValue).format('hh:mma'))}
                />
                <TimePicker
                    label="To"
                    className='create-form-input'
                    value={dayjs(to)}
                    onChange={(newValue) => setTo(dayjs(newValue).format('hh:mma'))}
                />
            </LocalizationProvider>
            <div className="modal-buttons-container">
                <LoadingButton
                    variant="outlined"
                    className="close-button"
                    onClick={onClose}>
                    Close
                </LoadingButton>
                <LoadingButton
                    variant="contained"
                    className="submit-button"
                    disabled={isDisabled}
                    loading={isSubmitting}
                    onClick={handleSubmit}>
                    Submit
                </LoadingButton>
            </div>
        </div>
    )
}

export default CreateModal;
